import {showLoginModal} from "./session-management/login";

import "./login-lock.css";
import configuration from "../../configuration";

export const initLoginLock = () => {
	if(!configuration.enableLoginLock){
		return;
	}
	const url = new URL(window.location.href);
	const hasJoinCourseId = url.searchParams.has("joinCourseId");
	if(hasJoinCourseId) {
		return;
	}
	// Get the lock status from the HTML Element
	const node = document.body.parentElement!;
	const lockStatus = node.getAttribute("lock-status") || "public";
	const shouldLock = !((lockStatus === "free") || (lockStatus === "unknown"));

	if(!shouldLock) {return;}

	window.addEventListener("ugm-logged-out", () => {
		showLoginModal(true);
	});
};
setTimeout(initLoginLock, 0);

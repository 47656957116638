import {apiGetSelf, apiLogin, apiLogout, apiSetMeta, apiGetCourses, ITeacher, IStudent, TCourse, apiGetCourseStudents, apiGetTeacherValidUntilByStudentId} from '@digitale-lernwelten/ugm-client-lib';
import configuration from '../../configuration';
import { syncPageViewHistory, PageViewHistoryEntry } from "../page-view-history/pageViewHistory";
import { getCourse, getUser, isLoggedIn, setUser, isTeacher } from './user-data';
import { getUnreadMessageCount } from './messages/messages';
import { getUnreadCorrectionCount } from './submissions/submissions';
import { clearCookie } from '../_helper';
import { showLoginModal } from './session-management/login';
import { isOverlayActive } from '../overlay/overlay';
import { hideAllEmbeddings, showLazyIframe } from '../content_types';

export const ugmSetMeta = async (val: Record<string, unknown>) => {
	if (!configuration.ugmEndpoint) { return; }
	const userData = getUser();
	if(!userData){return;}
	const oldData = JSON.stringify(userData);
	userData.meta = {...userData.meta, ...val};
	setUser(userData);
	const newData = JSON.stringify(userData);
	if(newData === oldData){return;} // Avoid sending requests out when nothing has visibly changed
	await apiSetMeta(val);
};

export const userSetActiveCourse = async (activeCourse:TCourse) => {
	if (!configuration.ugmEndpoint) { return; }
	if (!activeCourse) { return; }
	if(!isTeacher()){return;}
	const oldCourse = getCourse();
	if(oldCourse?.id === activeCourse.id){return;}
	await ugmSetMeta({activeCourse});
	if (oldCourse?.id) {
		const detail = { course: activeCourse };
		window.dispatchEvent(new CustomEvent("ugm-course-changed", {detail}));
	}
};

const checkActiveCourse = async () => {
	if (!configuration.ugmEndpoint) { return; }
	if(!isTeacher()){return;}
	const courses = await apiGetCourses();
	if(!courses){return;}
	if(courses.length < 0){return;}
	const urlParams = new URLSearchParams(window.location.search);
	const courseId = urlParams.get('course-id');
	if (courseId) {
		const target = courses.find(c => c.id === courseId) ?? courses[0];
		if(target){
			userSetActiveCourse(target);
		}
		window.history.replaceState({}, document.title, '/');
	} else {
		const course = courses[0]; // Just default to the first one
		if(course){
			userSetActiveCourse(course);
		}
	}
};

const refreshLoggedInAttribute = () => {
	if(!configuration.ugmEndpoint){return;}
	const userData = getUser();
	document.children[0].setAttribute("logged-in", isLoggedIn() ? "true" : "false");
	if (isLoggedIn()) {
		document.children[0].setAttribute("user-type", isTeacher() ? "teacher" : "student");
	} else {
		document.children[0].removeAttribute("user-type");
		if(configuration.enforceUGMLogin && !isOverlayActive()){
			showLoginModal(true);
		}
	}
	window.dispatchEvent(new CustomEvent(isLoggedIn() ? "ugm-logged-in" : "ugm-logged-out", { detail: userData }));
	syncPageViewHistory(userData?.meta?.pageViewHistory);
};
setTimeout(refreshLoggedInAttribute,0); // Needs to be deferred, since otherwise syncPageViewHistory isn't available

const ugmAfterLoginOrLogout = async (user: ITeacher | IStudent | null) => {
	if (!configuration.ugmEndpoint) { return null; }
	if(!user){
		clearCookie("sessionID");
		try {
			await apiLogout();
		} catch(e) {
			console.error(e);
		}
		setUser(null);
		refreshLoggedInAttribute();
		if(configuration.onlyAllowEmbedsForLoggedInUsers){
			hideAllEmbeddings(document.body).forEach(showLazyIframe);
		}
	} else {
		clearCookie("sessionID");
		setUser(user);
		if(!user?.meta?.activeCourse){
			checkActiveCourse();
		}
		refreshLoggedInAttribute();
		syncPageViewHistory(user?.meta?.pageViewHistory as PageViewHistoryEntry[] | null);
		if(configuration.onlyAllowEmbedsForLoggedInUsers){
			document.querySelectorAll<HTMLElement>('lazy-iframe.disallow-until-login').forEach(showLazyIframe);
		}
	}
	return user;
};

export const ugmLogout = async () => {
	if (!configuration.ugmEndpoint) { return null; }
	return await ugmAfterLoginOrLogout(null);
};

export const updateCurrentCourse = async () => {
	const user = getUser();
	if(!user || !isTeacher()){
		return;
	}
	const oldCourse = getCourse();
	const courses = await apiGetCourses();
	const activeCourse = oldCourse
		? courses.find(c => c.id === oldCourse.id)
		: courses.length > 0
			? courses[0]
			: null;
	if(activeCourse){
		await ugmSetMeta({activeCourse});
		await ugmCheckStatus();
	}
};

export const ugmLogin = async (username:string, password:string) => {
	window.dispatchEvent(new CustomEvent("ugm-logged-out"));
	if (!configuration.ugmEndpoint) { return null; }
	const { user } = await apiLogin(username, password);
	return await ugmAfterLoginOrLogout(user);
};

export const ugmCheckStatus = async () => {
	if (!configuration.ugmEndpoint) { return; }
	try {
		await ugmAfterLoginOrLogout(await apiGetSelf());
	} catch (e) {
		await ugmLogout();
	}
};
setTimeout(ugmCheckStatus, 0);

export const getUnreadNotificationCount = ():number => {
	return (getUnreadMessageCount() + getUnreadCorrectionCount())|0;
};


export const differenceInDays = (a:Date, b:Date):number => {
	const delta = b.getTime() - a.getTime();
	return Math.round(delta / (1000 * 3600 * 24));
};

export const daysUntil = (a:Date):number => {
	const now = new Date();
	now.setHours(0);
	now.setMinutes(0);
	now.setSeconds(0);
	return differenceInDays(now, a);
};

export const isTrialUser = async ():Promise<boolean> => {
	const user = await getUser();
	if((user.role !== "teacher") || (user.institutionRole !== "trial") || (!user.validUntil)){
		return false;
	}
	return true;
};

export const isFreeUser = async ():Promise<boolean> => {
	const user = await getUser();
	if((user.role !== "teacher") || (user.institutionRole !== "free")){
		return false;
	}
	return true;
};


export const daysLeftInTrial = async ():Promise<number> => {
	const user = await getUser();
	if((user.role !== "teacher") || (user.institutionRole !== "trial") || (!user.validUntil)){
		return -30;
	}
	return daysUntil(new Date(user.validUntil));
};

export const daysLeftWithValidLicense = async ():Promise<number> => {
	const user = await getUser();
	if((user.role !== "teacher") || (user.institutionRole !== "member") || (!user.validUntil)){
		return -30;
	}
	return daysUntil(new Date(user.validUntil));
};

export const teacherOfStudentDaysLeft = async ():Promise<number> => {
	const user = await getUser();
	const teacherValidUntil = await apiGetTeacherValidUntilByStudentId(user.id);
	if((user.role !== "student") || (!teacherValidUntil)){
		return -30;
	}
	return daysUntil(new Date(teacherValidUntil));
};

export const getCourseStudents = async (courseId = ""):Promise<IStudent[]> => {
	if(!courseId){
		const c = getCourse();
		if (c) {
			return await getCourseStudents(c.id);
		} else {
			return [];
		}
	}
	return (await apiGetCourseStudents(courseId)).sort((a,b) => (a.reminder || a.loginName).localeCompare((b.reminder || b.loginName)));
};

export const getCourseStudent = async (studentId: string):Promise<IStudent | undefined> => {
	const c = getCourse();
	if(!c){
		return undefined;
	}
	const students = await getCourseStudents(c.id);
	for(const s of students){
		if(s.id === studentId){
			return s;
		}
	}
	return undefined;
};

import './license-banner.css';
import { daysLeftInTrial, daysLeftWithValidLicense, isFreeUser, isTrialUser, teacherOfStudentDaysLeft } from '../user-group-management';
import { showModal } from '../../modal/modal';
import configuration from '../../../configuration';
import { isTeacher } from '../user-data';


const showExpiredModal = () => {
	const shopLink = configuration.target !== "netbook" ? "https://explore.eduplaces.de/de/app/lernwelten-module" : "/inhalt/netbook-bestellen";
	const alternativeShopLink = "https://docs.google.com/forms/d/e/1FAIpQLSdQ8EKabVrEZB0Xin8rnrGR5qBz3jl7gbJKyDmrOBtx0sOQHQ/viewform";
	const html = `<div class="login-modal">
		<form>
			<h3>Ihr Zugang ist abgelaufen.</h3>
			<br/>
			<p>Wenn Sie weiterhin Ihren Zugang nutzen möchten, können Sie eine Lizenz erwerben.</p>
			<br/>
			<a href="${shopLink}" style="margin-left:0" class="license-banner__button">Lizenz erwerben</a>
			<br/>
			<p style=${configuration.target === "netbook" ? "visibility: hidden" : ""}> Falls Ihre Schule noch keinen iServ / Eduplaces Zugang hat, können Sie auch
				<a href="${alternativeShopLink}"> hier direkt eine Lizenz anfragen.</a>
			</p>
		</form>
	</div>`;

	showModal(html, "centered-box", true);
};

const showStudentExpiredModal = () => {
	const html = `<div class="login-modal">
		<form>
			<h3>Dein Zugang ist abgelaufen.</h3>
			<br/>
			<p>Die Lizenz deiner Lehrkraft ist leider abgelaufen .</p>
			<br/>
		</form>
	</div>`;

	showModal(html, "centered-box", true);
};

const setupLicenseBanner = () => {
	window.addEventListener("ugm-logged-in", async () => {
		let daysLeftText = "";
		let remainingDays = 0;
		if (await isFreeUser()) {
			return;
		}
		if(!isTeacher()) {
			remainingDays = await teacherOfStudentDaysLeft();
		}
		if(!await isTrialUser() && isTeacher()){
			remainingDays = await daysLeftWithValidLicense();
			daysLeftText = remainingDays > 0
				? `Ihre Lizenz läuft in ${remainingDays} Tagen ab.`
				: `Ihr Zugang ist abgelaufen.`;
		} else if (isTeacher()) {
			remainingDays = await daysLeftInTrial();
			daysLeftText = remainingDays > 0
				? `Sie können noch ${remainingDays} Tage testen.`
				: `Ihr Testzugang ist abgelaufen.`;
		}
		if(document.querySelector("netbook-license-page")){
			return;
		}


		if(remainingDays > 7){
			return;
		} else if(remainingDays < 0){
			//const lockState = document.body.parentElement?.getAttribute("lock-status");
			// if(lockState === "free" || lockState === "unknown"){
			// 	return;
			// }
			if (isTeacher()) {
				showExpiredModal();
			} else {
				console.log("should be ere");
				showStudentExpiredModal();
			}
			return;
		}

		const banner = document.createElement('div');
		banner.classList.add('license-banner');

		const infoText = document.createElement('p');
		infoText.innerHTML = `Wir freuen uns, dass Sie sich für das netBook Deutsch interessieren.<br/>`;

		const remainingDaysContainer = document.createElement('div');
		remainingDaysContainer.classList.add('license-banner__remaining-days-container');

		const remainingDaysText = document.createElement('p');
		remainingDaysText.classList.add('license-banner__remaining-days');
		remainingDaysText.innerHTML = daysLeftText;
		remainingDaysContainer.appendChild(remainingDaysText);

		const buyLicenseButton = document.createElement('a');
		buyLicenseButton.classList.add('license-banner__button');
		buyLicenseButton.innerHTML = 'Lizenz erwerben';
		buyLicenseButton.href = window.location.origin + '/inhalt/netbook-bestellen';

		remainingDaysContainer.appendChild(remainingDaysText);
		remainingDaysContainer.appendChild(buyLicenseButton);

		banner.appendChild(infoText);
		banner.appendChild(remainingDaysContainer);
		const main = document.querySelector("main");
		if(main){
			main.prepend(banner);
		}
	});

	window.addEventListener("ugm-logged-out", () => {
		const banner = document.querySelector('.license-banner');
		if(banner) {
			banner.remove();
		}
	});
};

setTimeout(setupLicenseBanner, 0);
